import React, { useContext, useReducer } from 'react';
import PT from 'prop-types';
import KeywordMenu from '../KeywordMenu/KeywordMenu';
import KeywordSettings from '@containers/KeywordSettings/KeywordSettings';
import './KeywordNameCell.styles.scss';
import CanvasChart from "../CanvasChart/CanvasChart";
import { IconGear } from '@icons';
import { GlobalContext } from '@components/App/App.context';
import Icon from '@components/Icon/Icon';
import cn from "classnames";
import { usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import { FEATURE_TAGS } from "@constants/general";

function KeywordNameCell(
    {
        text,
        id,
        name,
        remove,
        extensions,
        paint,
        activate,
        chartData,
        activeKeywordId,
        onMouseEnter,
        onMouseLeave,
        viewMenuExamples,
        isFoodProfile,
        isUsa,
        isLoading,
        isTag,
    }
) {
    const { getRem } = useContext(GlobalContext);
    const isTagsEnabled = usePlatformFeatureFlag(FEATURE_TAGS);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        menuX,
        menuY,
        isMenuShown,
        settingsX,
        settingsY,
        isSettingsShown,
    } = state;

    const optionsKeywordMenu = [
        {
            title: "View menu examples",
            onClick: viewMenuExamples,
        },
    ];

    if (isFoodProfile && isUsa) {
        optionsKeywordMenu.unshift({
            title: "View profile",
            url: `/food-profile?id=${id}&name=${encodeURIComponent(name)}`,
        });
    }

    return (
        <div
            className={cn(
                'KeywordNameCell',
                chartData.data.length === 0 && 'KeywordNameCell--small-padding',
                isTagsEnabled && 'KeywordNameCell--with-tags',
            )}
            onMouseEnter={onMouseEnter || (() => {})}
            onMouseLeave={onMouseLeave || (() => {})}
        >
            {chartData.data.length > 1 ? (
                <div
                    className="KeywordNameCell__chart"
                    onClick={() => paint(name)}
                >
                    <CanvasChart
                        activeTabId={1}
                        chartDataType={1}
                        width={getRem(100)}
                        height={getRem(80)}
                        coefficient={getRem(80) / getRem(1350)}
                        charts={chartData}
                        hasPrediction={false}
                        isLightTheme={false}
                        onlyLine
                    />
                </div>
            ) : (
                <div
                    className="KeywordNameCell__circle"
                    style={{ color: chartData.chartInfo[0].color }}
                    onClick={() => paint(name)}
                />
            )}
            <button
                className="keyword-settings-btn"
                onClick={(event) => {
                    event.stopPropagation();
                    const boundingRect = event.currentTarget.getBoundingClientRect();
                    dispatch({
                        menuX: boundingRect.left,
                        menuY: boundingRect.top + boundingRect.height + 4,
                        isMenuShown: true,
                    });
                }}
            >
                <div className="keyword-settings-btn__dot"/>
                <div className="keyword-settings-btn__dot"/>
                <div className="keyword-settings-btn__dot"/>
            </button>
            <div
                className={cn(
                    'KeywordNameCell__text',
                    isTagsEnabled && !isTag && 'is-light',
                    isTagsEnabled && isTag && 'tag-label',
                )}
            >
                {text.toUpperCase()}
            </div>
            <div
                className="KeywordNameCell__text-overlay"
                onClick={() => activate(activeKeywordId === name.toLowerCase() ? '' : name.toLowerCase())}
            />
            <button
                className="remove-btn"
                onClick={() => remove(name)}
            >
                <div className="remove-btn__cross"/>
            </button>
            <button
                className="gear-btn"
                onClick={(event) => {
                    const boundingRect = event.currentTarget.getBoundingClientRect();

                    dispatch({
                        settingsX: boundingRect.right - 10,
                        settingsY: Math.min(window.innerHeight - 150, boundingRect.top + boundingRect.height / 2),
                        isSettingsShown: true,
                    });
                }}
                disabled={isLoading}
            >
                <Icon
                    type='small'
                    className={`gear-btn__icon ${extensions.length ? 'gear-btn__icon--active' : ''}`}
                >
                    <IconGear />
                </Icon>
            </button>
            <KeywordMenu
                x={menuX}
                y={menuY}
                closeModal={() => dispatch({ isMenuShown: false })}
                shown={isMenuShown}
                options={optionsKeywordMenu}
            />
            <KeywordSettings
                x={settingsX}
                y={settingsY}
                id={id}
                closeModal={() => dispatch({ isSettingsShown: false })}
                shown={isSettingsShown}
                includes={extensions.filter(item => item.wordType === 1 || item.wordType === 3)}
                excludes={extensions.filter(item => item.wordType === 2)}
                searchingKeyword={name.replace('"', '')}
            />
        </div>
    );
}

export default KeywordNameCell;

const initialState = {
    menuX: 0,
    menuY: 0,
    settingsX: 0,
    settingsY: 0,
    isMenuShown: false,
    isSettingsShown: false,
};

const reducer = (state = initialState, action) => {
    return { ...state, ...action, };
};

KeywordNameCell.propTypes = {
    color: PT.string,
    text: PT.string,
    keywords: PT.array,
};